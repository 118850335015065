div.container {

}
.one, .two, .three, .four, .five, .six, .seven, .eight, .circle {
  stroke-opacity: 0;
  transition: stroke-opacity 1s;
}
.two {
  
}
.three {

}
.four {

}
.five {
 
}
.six {

}
.seven {

}
.eight {

}

@keyframes show {
    0% {
        opacity: 0;
        /* display: none; */
    }
    
    100% {
        opacity: 1;
        /* display: inline; */
    }
      
  }
