.consentBox {
    display: flex;
    color: #fff;
}
.popupTitle, .popupContentWrapper {
    color: #72d0f3;
    font-family: 'Source Sans Pro', sans-serif !important;
}
.popupContent {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}
.popupContent div {
    font-size: 14px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}
.consent p {
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
}
.buttonAlternative {
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    border: 1px solid #fff;
    background: #fff;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    /* box-shadow: 0px 4px 5px #aaa; */
    /* color: #1c3943; */
    color: #72d0f3;
}

a[disabled] > .buttonAlternative {
    color: #666;
    background: #ddd;
}
.actionButtons {
    padding-right: 20px;
    margin-bottom: 20px;
}
.closeButton {
    color: #fff;
    padding-right: 15px;
    text-transform: uppercase;
    text-decoration: none;
}
.formArea {
    padding-top: 15px;
}