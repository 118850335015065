footer {

}
div.container {
    display: flex;
    justify-content: space-between;
    /* background-color: #2d6179; */
    background-color: #14345C;
    color: white;
    height: 100%;
    padding: 50px;
    flex-wrap: wrap;
}
div.container_left {
    display: flex;
    /* justify-content: flex-start; */
    flex-wrap: wrap;
}
div.container_right {
    display: flex;
    /* justify-content: flex-end; */
    flex-wrap: wrap;
}
div.item {
    padding: 10px; 
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
    min-width: 196px;
}
div.item:first-of-type {
    margin-right: 50px;
}
div.item_contact {
    padding: 0px 10px 10px 10px; 
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
}
div.item_social {
    padding: 0px 10px 10px 10px; 
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
}
div.item_address {
    padding: 0 10px 0 10px; 
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-align: left;
    line-height: 25px;
    display: flex;
    flex-direction: row;
}
div.item_livro {
    padding: 30px 10px 0 10px; 
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-align: left;
    line-height: 25px;
}
div.item_livro a, div.item_livro a:visited {
    color: white;
    text-decoration: none;
}
div.item_livro a:hover {
    color: #72d0f3;
}

div.item p {
    /* margin-top: 0;
    margin-bottom: 0; */
    font-size: 12px;
}
div.item p.title {
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
}
div.item table {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}
div.item table th {
    font-weight: normal;
    padding-right: 10px;
}
div.item table tr {
    margin: 0;
}

.icon {
    padding-right: 5px; 
}
.icon_2 {
    padding-right: 8px; 
}

div.item_social a {
    color: #fff;
    text-decoration: none;
}
div.item_social a:hover {
    color: #72d0f3;
}
/* @media (max-width: 1024px) {
    div.container {
        justify-content: left;
    }
} */

div.copyright {
    /* background-color: #2d6179; */
    background-color: #14345C;
    font-size: 12px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    padding-bottom: 20px;
    letter-spacing: .06rem;
}
div.copyright a {
    color: #fff;
}
div.copyright a:hover {
    color: #72d0f3;
}
p.footnote {
    font-size: 9px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 10px;
}