div.main {
    /* height: 600px; */
    width: 100%;
    margin: auto;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
}

div.content {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    position: relative;
}

.main_image {
    width: 100%;
}

.image_overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image_overlay_2 {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image_overlay_3 {
    position: absolute;
    bottom: 12%;
    right: 5%;
    /* transform: translate(-50%, -50%); */
}
.image_overlay_4 {
    position: absolute;
    bottom: 12%;
    left: 5%;
    /* transform: translate(-50%, -50%); */
}
.text_orange {
    color: #EF8325;
}

.iasfa_overlay {
    position: absolute;
    bottom: 12%;
    right: 5%;
    /* transform: translate(-50%, -50%); */
}

.iasfa_button {
    background-color: #14345c;
    color: #fff;
    padding: 10px 30px 10px 30px;
    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}

.image_overlay h1 {
    font-size: 40px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color: #fff;
    /* text-transform: uppercase; */
    background-color: rgba(28, 57, 67, 0.8);
    padding: 10px;
    margin-bottom: 20px;
}

.button {
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    border: 1px solid #fff;
    background: #fff;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}


.button:hover {
    background-color: #72d0f3;
    color: #fff;
}
.button_2 {
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    border: 1px solid #fff;
    background: #fff;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #72d0f3;
}

.buttonAlternative {
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    border: 1px solid #fff;
    background: #fff;
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0px 4px 5px #aaa;
    color: #1c3943;

}

.buttonAlternative:hover {
    /* background-color: #72d0f3;
    color: #fff; */
}

h1.services {
    font-size: 26px;
    margin: 0;
    margin-top: 50px;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 30px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    color: #14345c;
}

div.servicos_content {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

div.servicos_item {
    font-family: 'Montserrat', sans-serif;
    max-width: 300px;
    padding: 20px;

}

div.servicos_item h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #72aecf;
}

.icon {
    padding-right: 5px;
}

div.content h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

div.content_map {
    margin-top: 100px;
}

span.otica {
    color: #1c3943;

}

span.iris {
    color: #72d0f3;
}

a {
    color: #1c3943;
}

a:hover {
    text-decoration: none;
    /* border: 0px; */
}

.videoSlide {
    width: 100%;
}

.videoSlide_desktop {
    display: block;
}

.videoSlide_mobile {
    display: none;
}

.image_overlay_3 .button_icon_mobile {
    display: none;
    color: #14345c;
    border-radius: 50px;
    font-size: 25px;
    box-shadow: 0px 4px 5px #aaa;
    padding: 11px 15px;
}

/* .image_overlay_3 .button {
    display: block;
} */


@media only screen and (max-width: 899px) {
    h4 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .main_image {
        /* height: 300px; */
        object-fit: cover;
        object-position: 0 0;
    }

    .image_overlay h1 {
        font-size: 25px;
    }

    .image_overlay_2 {
        bottom: 5%;
    }

    .image_overlay_2 .button {
        font-size: 14px;
    }

    .videoSlide_desktop {
        display: none;
    }

    .videoSlide_mobile {
        display: block;
    }
    .image_overlay_3 {
        right: 6%;
    }

    .image_overlay_3 .button {
        display: none;
    }
    .image_overlay_3 .buttonAlternative {
        display: none;
    }

    .image_overlay_3 .button_icon_mobile {
        display: block;
        font-size: 200%;
    }
    .image_overlay_4 {
        bottom: 35%;
    }

}