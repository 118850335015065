.alternativa {
    font-size: 16px;
    max-width: 800px;
    margin: 50px auto 100px auto;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 25px;
}
.alternativah3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    color: #14345C;
}