div.container {
    position: relative;
    transition: opacity 1s;
}
div.background {
    position:fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
}
div.logo_wrapper {
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
div.title {
    opacity: 0;
    transition: opacity 1s;
}
div.title h1 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    
}
span.wrapper {
    box-shadow: 0px 4px 5px #aaa;
    padding: 10px;
    border: 1px solid #ffffff;
    border-radius: 30px;
}
span.otica {
    color: #1c3943;
    
}
span.iris {
    color: #72d0f3;
}
h3 {
    margin-top: 40px;
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    /* text-transform: uppercase; */
    
}
h3 .title {
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
}
.icon {
    padding-right: 5px; 
}
.icon_2 {
    padding-right: 8px; 
}