div.main {
    /* height: 600px; */
    width: 100%;
    margin: auto;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
}

div.content {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    position: relative;
    margin-bottom: 50px;
}

h1 {
    font-size: 26px;
    margin: 0;
    /* margin-top: 50px; */
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 30px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    color: #2d6179;
}

div.typeOf {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    flex-wrap: wrap;
    column-gap: 20px;
}

div.typeOfInverted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #72aecf;
    color: #ffffff;
}

div.item:first-of-type {
    margin-top: 30px;
}
div.itemWrapper {
    display: flex;
    flex-direction: row;
    padding: 25px;
}

.spaceFiller {
    height: 50px;
}
div.typeOf>div.item {
    /* background-color: #f0f0f0; */
    border: 1px dashed #2d6179;
    border-radius: 10px;
    max-width: 350px;
    max-height: 180px;
    min-height: 180px;
    /* padding: 25px; */
    margin-bottom: 20px;
    
}

div.itemSecondary {
    /* border: 1px dashed #2d6179; */
    border-radius: 10px;
    width: 350px;
    padding: 25px;
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: row;
}

div.item h3 {
    margin: 0 0px 10px 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #72aecf;
}

div.itemSecondary h3 {
    margin: 0 0px 10px 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #ffffff;
}

div.text_wrapper>div.item_detail {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}

div.icon_wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;
    /* min-width: 60px; */
}

.icon {
    font-size: 40px;
    /* float: left; */
    color: #72aecf;
}

.iconSecondary {
    font-size: 40px;
    /* float: left; */
    color: #ffffff;
}

div.text_wrapper {
    /* flex-grow: 2;
    flex-shrink: 2;
    flex-basis: 2;
    max-width: 270px;
    min-width: 270px; */
    margin-left: 20px;
}

div.item:hover {
    background-color: #2d6179;
}

div.item:hover>div.icon_wrapper>.icon {
    color: #fff;
}

div.item:hover>div.text_wrapper {
    color: #ffffff;
}

div.item:hover>div.text_wrapper>h3 {
    color: #fff;
}

div.formLine {
    margin-bottom: 15px;
    max-width: 450px;
    margin: auto;
}

div.formLine:first-of-type {
    margin-top: 40px;
    text-align: left;
    display: flex;
}

div.formLine:last-of-type {
    margin-bottom: 50px;
    margin-top: 50px;
}

.bigIcon {
    font-size: 50px;
}

div.formInLine:first-of-type {
    padding-right: 15px;
}

div.sub_content {
    margin-bottom: 15px;
    max-width: 450px;
    margin: auto;
}

h2 {
    font-size: 26px;
    margin: 0 0 0 0;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 30px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
}

h1.extraMargin {
    margin-bottom: 40px;
}

div.actionButtons {
    margin-top: 20px;
    margin-bottom: 20px;
}

div.footNote {
    font-family: 'Montserrat', sans-serif;
    line-height: 25px;
    font-weight: normal;
    font-size: 16px;
    max-width: 450px;
    margin: 50px auto 0px auto;
}

div.successMessage {
    max-width: 450px;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    margin-bottom: 80px;
}

@media only screen and (max-width: 600px) {
    div.typeOf {
        padding: 0px 10px 0px 10px;
    }
    div.itemWrapper {
        padding: 15px;
    }
    div.typeOf > div.item {
        padding: 5px;
    }
    .icon {
        font-size: 30px;
        /* float: left; */
        color: #72aecf;
    }
    div.formLine {
        padding: 15px;
    }
    div.sub_content {
        padding: 15px;
    }
}