div.main {
    /* height: 600px; */
    width: 100%;
    margin: auto;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
}
h2.titulo {
    font-size: 26px;
    margin: 0 0 40px 0;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 30px;
    text-transform: uppercase;
    position: relative;
    display: block;
    color: #14345c;
}

h2.titulo>.border {
    border-bottom: 2px solid #2d6179;
    opacity: 0.2;
    position: absolute;
    bottom: -5px;
    left: 20px;
    right: 20px;
}
div.about {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    max-width: 350px;
}
div.about_wide {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    max-width: 750px;
    margin: auto;
    padding: 50px;
}
div.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    max-width: 750px;
    margin: auto;
    gap: 50px;
    margin-bottom: 50px;
    justify-content: center;
}
div.gallery {
    max-width: 750px;
    margin: auto;
    padding-bottom: 100px;
}
div.team {
    max-width: 550px;
    margin: auto;
}
.main_image {
    width: 100%;
}
section.sobre_section {
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .imgGrid {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

