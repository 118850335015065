.logo_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 750px;
    margin: auto;
}

.logo_container {
    max-width: 250px;
    min-width: 250px;
    min-height: 100px;
    max-height: 100px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.escala {
    background-color: #666;
    max-width: 200px;
    max-height: 100px;
}

.brand_logo {
    max-width: 200px;
    max-height: 100px;
}

.divider {
    width: 100%;
    height: 100px;
    /* position:absolute; */
    left: 0px;
}

.divider:first-of-type {
    background: linear-gradient(to bottom right, #fff 49%, #c6dfed 50%);
}

.divider:nth-last-child(2) {
    background: linear-gradient(to left bottom, #c6dfed 49%, #fff 50%);
}

.protocolos section:nth-child(3) {
    background-color: #c6dfed;
    padding-bottom: 100px;
}
.protocolos section:last-child {
    margin-bottom: 100px;
}

h2 {
    color: #14345c;
    font-size: 26px;
    margin: 0 0 100px 0;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 30px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
}

h2>.border {
    border-bottom: 2px solid #2d6179;
    opacity: 0.2;
    position: absolute;
    bottom: -5px;
    left: 20px;
    right: 20px;
}
div.about {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    max-width: 750px;
    margin: auto;
    margin-bottom: 50px;
}
div.about {
    padding-right: 20px;
    padding-left: 20px;
}
span.highlight {
    color: #14345C;
    font-weight: 600;
}
