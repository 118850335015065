.logo_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 750px;
    margin: auto;
}

.logo_container {
    max-width: 250px;
    min-width: 250px;
    min-height: 100px;
    max-height: 100px;
    align-items: center;
    /* display: flex; */
    justify-content: center;
}
.logo2_container {
    max-width: 250px;
    min-width: 250px;
    min-height: 100px;
    max-height: 100px;
    padding-bottom: 40px;
    align-items: center;
    /* display: flex; */
    justify-content: center;
}

.marcas .brand_logo {
    max-width: 200px;
    max-height: 100px;
}

.marcas .divider {
    width: 100%;
    height: 100px;
    /* position:absolute; */
    left: 0px;
}

.marcas .divider:first-of-type {
    background: linear-gradient(to bottom right, #fff 49%, #c6dfed 50%);
}

.marcas .divider:nth-last-child(2) {
    background: linear-gradient(to left bottom, #c6dfed 49%, #fff 50%);
}

.marcas section:nth-child(3) {
    background-color: #c6dfed;
    padding-bottom: 100px;
}
.marcas section:last-child {
    margin-bottom: 100px;
}

.marcas h2 {
    font-size: 26px;
    margin: 0 0 100px 0;
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 30px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    color: #14345c;
}

.marcas h2>.border {
    border-bottom: 2px solid #2d6179;
    opacity: 0.2;
    position: absolute;
    bottom: -5px;
    left: 20px;
    right: 20px;
}