div.container {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-between;
    /* background-color: #2d6179; */
    /* border-bottom: 1px solid #2d6179; */
    border-bottom: 1px solid #14345C;
    /* color: #2d6179; */
    color: #14345C;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 5;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}

div.sub_container {
    display: flex;
    justify-content: flex-start;
}

div.item {
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 18px;
    padding-right: 20px;
}
div.item:first-of-type {
    padding-left: 0px;
    padding-right: 0px;
}
div.item:nth-of-type(2) {
    padding-left: 20px;
}

div.logo {
    padding-left: 10px;
}

.icon {
    padding-right: 10px;
}

div.item a,
div.item a:visited, a.navBar, a.navBar:visited {
    text-decoration: none;
    /* color: #2d6179; */
    color: #14345C;
    border-bottom: 3px solid #ffffff;
    text-transform: uppercase;
}

div.item a:hover, a.navBar:hover {
    color: #72d0f3;
    border-bottom: 3px solid #2d6179;
}

.AppNavigationBar {
    padding-left: 16px;
}
.NavBarTitle {
    text-align: center;
    width: 100%;
    position: absolute;
    padding-top: 4px;
}
.hamburgerMenu_inverted {
    color: white;
}
.badgeHM {
    padding-left: 15px;
    margin-top: -4px;
}
p.footnote {
    font-size: 9px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 10px;
}