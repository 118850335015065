div.main {
    /* height: 600px; */
    width: 90%;
    margin: auto;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    color: #000;
    z-index: 10;
    position: relative;
}

div.content {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    position: relative;
}

div.main > h1 {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    /* margin-top: 50px; */
    font-family: 'Source Sans Pro', sans-serif;
    padding-top: 100px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    color: #14345c;
    max-width: 650px;
}

div.campaignBody {
    background-color: white;
    display: flex;
    width: 100%;
    position: absolute;
}

div.logoWrapper {
    min-width: 100%;
    min-height: 100%;
    align-items: stretch;
    margin-top: -50%;
    left: -50%;
    position: relative;
    opacity: 0.5;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.highlight {
    color: #72d0f3;
}

.textfield,
.textfield input,
.textfield label {
    background-color: white;
    color: #000;
}

.textfield {
    border: 0px;
    border-bottom: 1px solid #aaa;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
}

.textfield:focus {
    outline: none;
}

div.formLine {
    max-width: 450px;
    margin: auto;
    margin-top: 35px;
}

div.formLineConsent {
    max-width: 450px;
    margin: auto;
    margin-top: 35px;
    display: flex;
    align-items: flex-start;

}

.formLine label,
.formLineConsent label {
    padding-left: 6px;
    font-size: 11px;
    text-align: left;
}

.paragraph {
    margin-top: 50px;
    margin-bottom: 50px;
}
.paragraph_smaller {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 13px;
}

.submitButton {
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    background-color: #72aecf;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    border: 0;
    cursor: pointer;
}

.checkbox {}

.root {
    background-color: white;
}

input::-webkit-calendar-picker-indicator {
    /* background-color: black;
    color-scheme: dark; */
}

.birthdateLabel {
    color: gray;
}

.doubleFormLine {
    max-width: 450px;
    margin: auto;
    margin-top: 35px;
    display: flex;
    align-items: flex-start;
    align-content: flex-end;
    justify-content: center;
    gap: 15px;
}

.doubleFormLine div {
    min-width: 150px;
    width: 100%;
}

.doubleFormLine label {
    font-size: 11px;
    text-align: left;
    float: left;
}

input[type=submit]:disabled {
    background-color: #ccc;
}
.meme {
    width: 100%;
    border-radius: 10px;
}
div.logoButton {
    margin-top: 50px;
}
div.logoButton h1 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
}
span.wrapper {
    box-shadow: 0px 4px 5px #666;
    padding: 10px 20px 10px 20px;
    border: 1px solid #ffffff;
    border-radius: 50px;
    background-color: #fff;
}
span.otica {
    color: #1c3943;
    
}
span.iris {
    color: #72d0f3;
}
.goBack {
    text-transform: uppercase;
    font-size: 12px;
}
.goBack a, .goBack a:visited, .goBack:hover {
    color: white;
}
.fixPosition {
    position: relative;
}

@media only screen and (max-width: 600px) {
    .logoWrapper {
        margin-top: 0 !important;
        left: 0 !important;
    }

    div.main > h1 {
        /* text-shadow: #000000 0 0 10px; */
    }

}

@media only screen and (max-width: 450px) {
    .doubleFormLine {
        flex-wrap: wrap;
    }
}