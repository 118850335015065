div.container {
    /* background: url('../../../public/iris-original.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.one {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #72d0f3;
    /* stroke-opacity: 50%; */
  animation-name: rotate;
  animation-duration: 4s;
  animation-direction:normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.two {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #72d0f3;
  animation-name: rotate;
  animation-duration: 2s;
  animation-delay: 0.2s;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.three {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #1c3943;
  animation-name: rotate;
  animation-duration: 2s;
  animation-delay: 0.2s;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.four {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #1c3943;
  animation-name: rotate;
  animation-duration: 4s;
  animation-delay: 0.5s;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.five {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #2d6179;
    animation-iteration-count: infinite;
  animation-name: rotate-five;
  animation-duration: 2s;
  animation-delay: 0.8s;
  animation-direction:reverse;
  animation-timing-function: linear;
}
.six {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #2d6179;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
  animation-name: rotate-five;
  animation-delay: 0.2s;
  animation-duration: 2s;
  animation-direction:reverse;
  animation-timing-function: linear;
}
.seven {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #72aecf;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
  animation-name: rotate-five;
  animation-duration: 7s;
  animation-direction:reverse;
  animation-timing-function: linear;
}
.eight {
    stroke-dashoffset: 0;
    stroke-dasharray: 261;
    stroke: #72aecf;
    animation-iteration-count: infinite;
    animation-delay: 0.3s;
  animation-name: rotate-five;
  animation-duration: 2s;
  animation-direction:reverse;
  animation-timing-function: linear;
}

@keyframes rotate {
    0% {
        stroke-dashoffset: 0;
    }
    
    100% {
        stroke-dashoffset: 522;
    }
      
  }

  @keyframes rotate-five {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 522;
    }
      
  }